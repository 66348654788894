<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Panel de sensores</b-card-title>
      <b-card-text
        v-show="device.processedTimestamp"
        class="mt-xl-0 mt-md-0 mt-lg-0 mt-1 mr-25 mb-0"
      >
        <b-badge variant="light-warning">
          Actualizado: {{ device.processedTimestamp }}
        </b-badge>
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row v-if="false">
        <b-col
          v-for="(item, itemIndex) in actuators"
          :key="item.icon"
          md="3"
          sm="6"
          class="mb-2 mb-md-0"
          :class="item.customClass"
        >
          <b-media
            no-body
            class="cursor-pointer"
            @click="emitActuatorEvent(item, itemIndex)"
          >
            <b-media-aside class="mr-2">
              <b-avatar
                size="40"
                :variant="item.state ? item.color : 'light-danger'"
              >
                <feather-icon
                  size="20"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h5 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h5>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="4"
          sm="6"
          class="mb-2 mb-md-0"
        >
          <!-- ph starts -->
          <b-media
            no-body
            class="cursor-pointer"
          >
            <b-media-aside class="mr-2">
              <b-avatar
                size="40"
                variant="light-info"
              >
                <feather-icon
                  size="20"
                  icon="LoaderIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h5 class="font-weight-bolder mb-0">
                {{ sensorsData.ph }}
              </h5>
              <b-card-text class="font-small-3 mb-0">
                pH
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <!-- bright starts -->
        <b-col
          md="4"
          sm="6"
          class="mb-2 mb-md-0"
        >
          <b-media
            no-body
            class="cursor-pointer"
          >
            <b-media-aside class="mr-2">
              <b-avatar
                size="40"
                variant="light-warning"
              >
                <feather-icon
                  size="20"
                  icon="SunIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h5 class="font-weight-bolder mb-0">
                {{ sensorsData.brightness }} Lx
              </h5>
              <b-card-text class="font-small-3 mb-0">
                Luminosidad
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <!-- bright ends -->
        <!-- radiation starts -->
        <b-col
          md="4"
          sm="6"
          class="mb-2 mb-md-0"
        >
          <b-media
            no-body
            class="cursor-pointer"
          >
            <b-media-aside class="mr-2">
              <b-avatar
                size="40"
                variant="light-danger"
              >
                <feather-icon
                  size="20"
                  icon="ActivityIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h5 class="font-weight-bolder mb-0">
                {{ sensorsData.radiation }}
              </h5>
              <b-card-text class="font-small-3 mb-0">
                Radiación
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <!-- radiation ends -->
      </b-row>
      <b-row class="mt-3">
        <!-- conductivity starts -->
        <b-col
          md="4"
          sm="6"
          class="mb-2 mb-md-0"
        >
          <b-media
            no-body
            class="cursor-pointer"
          >
            <b-media-aside class="mr-2">
              <b-avatar
                size="40"
                variant="light-primary"
              >
                <feather-icon
                  size="20"
                  icon="ZapIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h5 class="font-weight-bolder mb-0">
                {{ sensorsData.conductivity }}
              </h5>
              <b-card-text class="font-small-3 mb-0">
                Conductividad
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <!-- conductivity ends -->
        <!-- upper level sensor starts -->
        <b-col
          md="4"
          sm="6"
          class="mb-2 mb-md-0"
        >
          <b-media
            no-body
            class="cursor-pointer"
          >
            <b-media-aside class="mr-2">
              <b-avatar
                size="40"
                :variant="sensorsData.upper_sensor_level ? 'light-success' : 'light-danger'"
              >
                <feather-icon
                  size="20"
                  icon="ArrowUpCircleIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h5 class="font-weight-bolder mb-0">
                {{ sensorsData.upper_sensor_level ? 'ON' : 'OFF' }}
              </h5>
              <b-card-text class="font-small-3 mb-0">
                Sensor de nivel superior
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <!-- upper level sensor ends -->
        <!-- upper level sensor starts -->
        <b-col
          md="4"
          sm="6"
          class="mb-2 mb-md-0"
        >
          <b-media
            no-body
            class="cursor-pointer"
          >
            <b-media-aside class="mr-2">
              <b-avatar
                size="40"
                :variant="sensorsData.lower_sensor_level ? 'light-success' : 'light-danger'"
              >
                <feather-icon
                  size="20"
                  icon="ArrowDownCircleIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h5 class="font-weight-bolder mb-0">
                {{ sensorsData.lower_sensor_level ? 'ON' : 'OFF' }}
              </h5>
              <b-card-text class="font-small-3 mb-0">
                Sensor de nivel inferior
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <!-- upper level sensor ends -->
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BBadge,
  },
  computed: {
    sensorsData() {
      return this.$store.getters['deviceStoreModule/device'].sensorsData
    },
    device() {
      return this.$store.getters['deviceStoreModule/device']
    },
    actuators() {
      return this.$store.state.deviceStoreModule.deviceActuators
    },
  },
  methods: {
    emitActuatorEvent(actuator, actuatorIndex) {
      this.$emit('on-emit-actuator-event', {
        ...actuator,
        actuatorIndex,
      })
    },
  },
}
</script>
